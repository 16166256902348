<template>
    <v-dialog v-model="dialog" persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="getDataEstrella()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Hogares Inscritos" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="hogarInscrito.nombre"
                            label="Nombre del Hogar"
                            :error-messages="nombreErrors"
                            @input="$v.hogarInscrito.nombre.$touch()"
                            @blur="$v.hogarInscrito.nombre.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Comité Encargado de la Revisión del Informe"
                            v-model="hogarInscrito.comiteRevision"
                            :error-messages="comiteErrors"             
                            @change="$v.hogarInscrito.comiteRevision.$touch()"
                            @blur="$v.hogarInscrito.comiteRevision.$touch()"
                            counter="100"
                            required>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Persona de Contacto"
                            v-model="hogarInscrito.personaContacto"
                            :error-messages="personaErrors"             
                            @change="$v.hogarInscrito.personaContacto.$touch()"
                            @blur="$v.hogarInscrito.personaContacto.$touch()"
                            counter="200"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Teléfono"
                            v-model="hogarInscrito.telefono"
                            :error-messages="telefonoErrors"  
                            v-mask="telMask"           
                            @change="$v.hogarInscrito.telefono.$touch()"
                            @blur="$v.hogarInscrito.telefono.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Correo"
                            v-model="hogarInscrito.correo"
                            :error-messages="correoErrors"             
                            @change="$v.hogarInscrito.correo.$touch()"
                            @blur="$v.hogarInscrito.correo.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Estrellas Ganadas"
                            v-model="hogarInscrito.estrellasGanadas"
                            :error-messages="estrellasErrors"             
                            @change="$v.hogarInscrito.estrellasGanadas.$touch()"
                            @blur="$v.hogarInscrito.estrellasGanadas.$touch()"
                            required> 
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Calificación Final"
                            v-model="hogarInscrito.calificacionFinal"
                            :error-messages="calificacionErrors"             
                            @change="$v.hogarInscrito.calificacionFinal.$touch()"
                            @blur="$v.hogarInscrito.calificacionFinal.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Informes Recibidos"
                            v-model="hogarInscrito.informesRecibidos"
                            :error-messages="recibidosErrors"             
                            @change="$v.hogarInscrito.informesRecibidos.$touch()"
                            @blur="$v.hogarInscrito.informesRecibidos.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Informes Aprobados"
                            v-model="hogarInscrito.informesAprobados"
                            :error-messages="aprobadosErrors"             
                            @change="$v.hogarInscrito.informesAprobados.$touch()"
                            @blur="$v.hogarInscrito.informesAprobados.$touch()"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Años Acompañando Hogar"
                            v-model="hogarInscrito.aniosAcomp"
                            :error-messages="aniosAcompErrors"             
                            @change="$v.hogarInscrito.aniosAcomp.$touch()"
                            @blur="$v.hogarInscrito.aniosAcomp.$touch()"
                            type="number"
                            required>
                        </v-text-field> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-checkbox 
                            label="Galardon anterior"                     
                            v-model="hogarInscrito.galardonAnterior"
                            color="#0B3F67"
                            :value="true">                    
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificar">Modifica</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "@/axios";
import { mask } from "vue-the-mask";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, email, integer, decimal, minValue, maxValue, maxLength } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    directives: {
        mask,
    },
    props: [
        'id',
    ],
    computed: {
        idInstalacion() {
            return this.$store.getters.sucursal.id;
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        nombreErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.nombre.$dirty) return errors            
            !this.$v.hogarInscrito.nombre.required && errors.push('El nombre del hogar es requerido.')
            !this.$v.hogarInscrito.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')            
            return errors
        },
        comiteErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.comiteRevision.$dirty) return errors            
            !this.$v.hogarInscrito.comiteRevision.required && errors.push('El nombre del comité es requerido.')
            !this.$v.hogarInscrito.comiteRevision.maxLength && errors.push('No debe exceder el tamaño permitido.')            
            return errors
        },
        personaErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.personaContacto.$dirty) return errors            
            !this.$v.hogarInscrito.personaContacto.required && errors.push('El nombre de la persona de contacto es requerido.')            
            !this.$v.hogarInscrito.personaContacto.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        telefonoErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.telefono.$dirty) return errors            
            !this.$v.hogarInscrito.telefono.required && errors.push('El teléfono es requerido.')            
            return errors
        },
        correoErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.correo.$dirty) return errors            
            !this.$v.hogarInscrito.correo.required && errors.push('El correo es requerido.')            
            !this.$v.hogarInscrito.correo.email && errors.push('El formato del correo es inválido.')            
            return errors
        },
        estrellasErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.estrellasGanadas.$dirty) return errors            
            !this.$v.hogarInscrito.estrellasGanadas.required && errors.push('Las estrellas ganadas son requeridas.')            
            !this.$v.hogarInscrito.estrellasGanadas.integer && errors.push('Solo debe digitar números.')            
            return errors
        },
        calificacionErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.calificacionFinal.$dirty) return errors            
            !this.$v.hogarInscrito.calificacionFinal.required && errors.push('La calificación final es requerida.')            
            !this.$v.hogarInscrito.calificacionFinal.decimal && errors.push('Solo debe digitar números.')
            !this.$v.hogarInscrito.calificacionFinal.minValue && errors.push('La calificación debe ser mayor a 0.')
            !this.$v.hogarInscrito.calificacionFinal.maxValue && errors.push('La calificación debe ser menor a 100.')
            return errors
        },
        recibidosErrors () {
            const errors = []
            if (!this.$v.hogarInscrito.informesRecibidos.$dirty) return errors            
            !this.$v.hogarInscrito.informesRecibidos.required && errors.push('La cantidad de informes recibidos es requerida.')            
            !this.$v.hogarInscrito.informesRecibidos.decimal && errors.push('Solo debe digitar números.')            
            return errors
        },
        aprobadosErrors() {
            const errors = []
            if (!this.$v.hogarInscrito.informesAprobados.$dirty) return errors            
            !this.$v.hogarInscrito.informesAprobados.required && errors.push('La cantidad de informes aprobados es requerida.')            
            !this.$v.hogarInscrito.informesAprobados.decimal && errors.push('Solo debe digitar números.')            
            return errors
        },
        aniosAcompErrors () {
            const errors = []
            if (!this.$v.hogarInscrito.aniosAcomp.$dirty) return errors            
            !this.$v.hogarInscrito.aniosAcomp.required && errors.push('Dato es requerido.')
            !this.$v.hogarInscrito.aniosAcomp.integer && errors.push('Solo debe digitar números.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        telMask: '####-####',
        hogarInscrito: {
            nombre: '',
            comiteRevision: '',
            personaContacto: '',
            telefono: '',
            correo: '',
            estrellasGanadas: '',
            calificacionFinal: '',
            informesRecibidos: '',
            informesAprobados: '',
            aniosAcomp: 0,
            galardonAnterior: null
        },
    }),
    validations: {
        hogarInscrito: {
            nombre: { required, maxLength: maxLength(100) },
            comiteRevision: { required, maxLength: maxLength(100) },
            personaContacto: { required, maxLength: maxLength(100) },
            telefono: { required },
            correo: { required, email },
            estrellasGanadas: { required, integer },
            calificacionFinal: { required, decimal, minValue: minValue(0), maxValue: maxValue(100) },
            informesRecibidos: { required, decimal },
            informesAprobados: { required, decimal },
            aniosAcomp: { required, integer },
        },
    },
    methods: {
        cancelar() {
            this.hogarInscrito = {};
            this.dialog = false;
            this.loading = false;
            this.$v.hogarInscrito.$reset();
        },
        getDataEstrella() {
            this.loading = true;
            axios.get("/estrellas/getHogarInscrito/"+this.id)
            .then(response => {
                this.hogarInscrito = response.data.hogar;                      
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificar() {
            let hogar = this.hogarInscrito;
            delete hogar.docEvidencia;
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                axios.put("/estrellas/modificarHogarInscrito", hogar, { params: {
                    idUsuario: this.idUsuario,
                }})
                .then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Hogar modificado", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al modificar el hogar " + error);
                    this.$store.dispatch("noti", { 
                        mensaje: "Ocurrió un error no se modificó el hogar",
                        color: "error"
                    });
                    this.cancelar();
                  }
                });
            }
        }
    }
};
</script>
